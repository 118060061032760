import ossProtype from '@/common/ossVideo.js';
import _ from 'lodash';
import recordUpload from '@/utils/recordUpload';
import _base_https from '@/api/base_api';

export default {
  data () {
    return {
      progress: 0,
      is_progress: false,
      _oss: {
        status: '',
        client: null
      },
      uploader: null,
      video_id: 0
    };
  },
  created () {
    this._initOSSAuth();
  },
  methods: {
    _initOSSAuth () {
      if (this._oss) {
        if (this._oss.status === 'success' || this._oss.status === 'pedding') {
          return;
        }
      }
      let judge_url = window.location.href.split('/').indexOf('admin.afanticar.com');
      this.module_id = window.location.search.split('=')[1];
      let bucket = judge_url != -1 ? 'fhl-ai-video' : 'fhl-ai-video-test';
      this._oss = {
        status: 'pedding',
        client: null
      };
      ossProtype(bucket).then((res) => {
        if (res) {
          this._oss = {
            status: 'success',
            client: res
          };
        } else {
          this._oss = {
            status: 'failure',
            client: null
          };
          this.$message.error(res);
        }
      })
        .catch(err => {
          this._oss = {
            status: 'failure',
            client: null
          };
          this.$message.error('获取oss客户端失败');
          console.error(err);
        });
    },
    async multipartUpload (file, onSuccess, file_index) {
      try {
        const this_ = this;
        const time = this.$moment()._d;
        const date = `${ time.getFullYear() }-${ time.getMonth() + 1 }-${ time.getDate() }`;
        const url = this.custom_request_type + '/' + date + '/' + file.name;
        const [record, removeRecord] = recordUpload(url);

        !file_index ? file_index = 0 : '';
        const data = await this._oss.client.multipartUpload(url, file, {
          progress: function (p, checkpoint) {
            if (file_index >= this_.file_list.length) {
              return;
            }
            let fileObj = this_.file_list[file_index];
            fileObj.percent = p * 100;
            fileObj.status = 'uploading';

            //显示百分比进度
            this_.progress = parseInt(p * 100);
            if (p == 1) {
              this_.is_progress = false;
            } else {
              !this_.is_progress ? this_.is_progress = true : '';
            }

            record(checkpoint);
          }
        });
        removeRecord();
        let dataUrl = _.get(data, 'res.requestUrls[0]');
        dataUrl = dataUrl.split('?')[0];
        data.url = dataUrl;
        // console.log(data)
        onSuccess(data, dataUrl);
      } catch (err) {
        console.error(err);
        this.file_list = [];
        this._initOSSAuth();
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async normalUpload (file, onSuccess, onProgress) {
      try {
        const self = this;
        const time = this.$moment()._d;
        const date = `${ time.getFullYear() }-${ time.getMonth() + 1 }-${ time.getDate() }`;
        const url = this.custom_request_type + '/' + date + '/' + file.name;
        const data = await this._oss.client.put(url, file);

        onSuccess(data, data.url);
      } catch (err) {
        console.error(err);
        this.file_list = [];
        this._initOSSAuth();
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async normalUploadFile (file, onSuccess, fileParams) {
      try {
        _base_https.uploadFile(fileParams).then(res => {
          if (res.code === 200) {
            onSuccess(res.data, res.data.url);
          } else {
            this.file_list = [];
            this.$message.error('请重新上传或者刷新页面');
          }
        });
      } catch (err) {
        console.error(err);
        this.file_list = [];
        this.$message.error(`请重新上传或者刷新页面`);
      }
    },
    async vodUpload (file, onSuccess, onProgress) {
      let userData = '{"Vod":{}}';
      if (this.uploader) {
        this.uploader.stopUpload();
      }
      this.uploader = this.createUploader(file, onSuccess, onProgress);
      this.uploader.addFile(file, null, null, null, userData);
      this.uploader.startUpload();
    },
    createUploader (file, onSuccess, onProgress) {
      const self = this;
      let uploader = new AliyunUpload.Vod({
        timeout: this.uploader_info || 60000,
        partSize: self.partSize || 1048576,
        parallel: self.parallel || 5,
        retryCount: self.retryCount || 3,
        retryDuration: self.retryDuration || 2,
        userId: self.userId || '123',

        // 开始上传
        onUploadstarted: function (uploadInfo) {
          if (uploadInfo.videoId) {
            let params = {
              videoId: uploadInfo.videoId
            };
            _base_https.refreshVod(params).then(res => {
              console.log('重新上传');
              if (res.code === 200) {
                let uploadAuth = res.data.uploadAuth;
                let uploadAddress = res.data.uploadAddress;
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress);
              } else {
                self.$message.error('刷新凭证失败');
              }
            });
          } else {
            _base_https.createVod(self.params_data).then(res => {
              if (res.code === 200) {
                let uploadAuth = res.data.uploadAuth;
                let uploadAddress = res.data.uploadAddress;
                let videoId = res.data.videoId;
                this.video_id = videoId;
                uploader.setUploadAuthAndAddress(uploadInfo, uploadAuth, uploadAddress, videoId);
              } else {
                self.$message.error('获取凭证失败');
              }
            });
          }

        },
        // 文件上传成功
        onUploadSucceed: function (uploadInfo) {
          // let endpoint = uploadInfo.endpoint.split('//')
          // let url = `${endpoint[0]}//${uploadInfo.bucket}.${endpoint[1]}/${uploadInfo.object}` //视频原始地址

          // let url = `https://vod.afanticar.com/${uploadInfo.object}` //cdn加速的域名
          // onSuccess(uploadInfo, url)
          //获取cdn加速的域名
          let domain = 'https://vod.afanticar.com';

          _base_https.getUrlAuthKey().then(res => {
            if (res.code === 200) {
              domain = res.data.cdnDomainName;
            } else {
              console.error('无法获取url domain');
            }

            let url = `${ domain }/${ uploadInfo.object }`; //cdn加速的域名
            onSuccess(uploadInfo, url);
          });
        },
        // 文件上传失败
        onUploadFailed: function (uploadInfo, code, message) {
          self.$message.error('文件上传失败');
          self.file_list = [];
        },
        // 文件上传进度，单位：字节, 可以在这个函数中拿到上传进度并显示在页面上
        onUploadProgress: function (uploadInfo, totalSize, progress) {
          console.log('onUploadProgress:file:' + uploadInfo.file.name + ', fileSize:' + totalSize + ', percent:' + Math.ceil(progress * 100) + '%');
          let progressPercent = Math.ceil(progress * 100);
          self.progress = progressPercent;
          onProgress({percent: progressPercent});

          if (progressPercent == 100) {
            self.is_progress = false;
          } else {
            !self.is_progress ? self.is_progress = true : '';
          }
        },
        // 上传凭证超时
        onUploadTokenExpired: function (uploadInfo) {
          let params = {
            videoId: self.video_id
          };
          _base_https.refreshVod(params).then(res => {
            if (res.code === 200) {
              let uploadAuth = res.data.UploadAuth;
              uploader.resumeUploadWithAuth(uploadAuth);
            } else {
              self.$message.error('刷新凭证失败');
            }
          });
        },
        // 全部文件上传结束
        onUploadEnd: function (uploadInfo) {
          //console.log('onUploadEnd: uploaded all the files')
        }
      });

      console.log(uploader);
      return uploader;
    }
  }
};
