<template>
  <div :class="listType == 'text' ? 'upload-wrap-box' : 'upload-wrap'">
    <div class="upload-box">
      <a-upload
        :custom-request="customRequest"
        :list-type="listType"
        :accept="acceptList"
        :file-list="file_list"
        :before-upload="beforeUpload"
        :remove="handleRemove"
        @preview="handlePreview"
        @change="handleChange"
      >
        <div v-if="file_list.length < 1">
          <a-button v-if="listType == 'text'">
            <a-icon type="upload" />
            {{ uploadTitle }}
          </a-button>
          <div v-else>
            <a-icon type="plus"></a-icon>
            <div class="ant-upload-text">{{ uploadTitle }}</div>
          </div>
        </div>
      </a-upload>
      <div class="progress" v-if="is_progress">{{ progress }}%</div>
    </div>
    <div class="bottom">
      <span class="upload-title" v-if="isTitle">{{ uploadTitle }}</span>
      <a-switch size="small" v-model="is_vod" v-if="isVod" />
    </div>
  </div>
</template>

<script>
import ossUploadMixin from './ossUploadMixin';
import utils from '@/common/util';

export default {
  mixins: [ossUploadMixin],
  props: {
    //允许上传的类型
    acceptList: {
      type: String,
      default: '.png,.jpg,.jpeg,.webp,.mp4,.mov,.m4v',
    },
    //标题
    uploadTitle: {
      type: String,
      default: '上传',
    },
    //上传的错误提示
    uploadMessage: {
      type: String,
      default: '请上传指定格式的文件',
    },
    //已上传的内容
    hasFileList: {
      type: Array,
      default: null,
    },
    //允许通过的文件大小
    limitSize: {
      type: Number,
      default: 10000,
    },
    //允许通过的文件个数
    limitFileLength: {
      type: Number,
      default: 1,
    },
    //是否需要返回时长
    isDuration: {
      type: Boolean,
      default: false,
    },
    //是否需要上传到点播
    isVod: {
      type: Boolean,
      default: false,
    },
    //点播 - 凭证数据
    paramsData: {
      type: Object,
      default: () => {},
    },
    //点播 - 上传视频归属业务信息
    extendData: {
      type: String,
      default: '',
    },
    //点播 - 业务系统对应的clientId，总后台：admin，聚金塔：jjt-admin，百夫长：bfz-admin，风火轮后台：fhl-admin，默认fhl-admin
    clientId: {
      type: String,
      default: 'admin',
    },
    //是否需要断点续传
    isMultipart: {
      type: Boolean,
      default: false,
    },
    //是否普通上传(需要传fileParams对应的参数)
    isNormal: {
      type: Boolean,
      default: false,
    },
    /*上传文件需要提交的参数(business_code, need_compress, type)
     * business_code：所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
     * need_compress：是否需要压缩处理-针对图片(1:是  2:否  默认是)
     * type：文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
     */
    fileParams: {
      type: Object,
      default: () => {},
    },
    // 是否底部显示标题
    isTitle: {
      type: Boolean,
      default: false,
    },
    // 上传样式
    listType: {
      type: String,
      default: 'picture-card',
    },
    //是否获取宽高
    getWidth: {
      type: Boolean,
      default: false,
    },
    maxW_H: {
      type: String,
      default: '',
    },
    maxWidthErrorMsg: {
      type: String,
      default: '',
    },
    showMaxErrorMsg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      file_type_list: [],
      file_list: [],
      custom_request_type: 'image',
      thumb_url:
        'http://afanticar-test.oss-cn-hangzhou.aliyuncs.com/adsys/2020/10/xQVHkI8uIrzoICsANthwzqqhZtAUUUmIqUr0uJiX.jpeg',
      video_suffix_list: ['mp4', 'mov', 'm4v'],
      is_vod: true,
      params_data: {
        fileName: '',
        title: '',
      },
      progress: 0,
      set_progress: null,
      upload_file_params: {
        business_code: 'bfz', //所属业务组编码(风火轮：fhl  聚金塔：jjt 百夫长：bfz 数据组-微控 vkong-new)
        need_compress: 1, //是否需要压缩处理-针对图片(1:是  2:否  默认是)
        type: 1, //文件类型(1:图片文件,2:音频文件,3:视频文件,4:文本文件,5:文档文件,6:emoji,7:其他)
      },
    };
  },
  watch: {
    hasFileList: {
      handler: function (data) {
        if (data) {
          this.file_list = data;
        }
      },
      immediate: true,
    },
    paramsData: {
      handler: function (data) {
        if (data) {
          Object.assign(this.params_data, JSON.parse(JSON.stringify(data)), {
            extendData: this.extendData,
            clientId: this.clientId,
          });
        }
      },
      immediate: true,
    },
    fileParams: {
      handler: function (data) {
        if (data) {
          Object.assign(this.upload_file_params, JSON.parse(JSON.stringify(data)));
        }
      },
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    async customRequest(option) {
      this.is_progress = true;
      const { file, onSuccess, onProgress } = option;

      /* 1.如果是需要分片上传的话，走multipartUpload
       *  2.video默认走vodUpload，其他默认走normalUploadFile
       *  3.如果设置了 is_vod=false，则都走normalUploadFile
       *  4.如果设置了isNolmal=true，则走normalUploadFile
       */
      if (this.isMultipart) {
        await this.multipartUpload(file, onSuccess).then((res) => {
          this.is_progress = false;
        });
      } else {
        if (
          this.custom_request_type === 'image' ||
          this.custom_request_type === 'file' ||
          !this.is_vod ||
          this.isNormal
        ) {
          this.onProgress(file, onProgress);

          // await this.normalUpload(file, onSuccess).then(res => {
          //   onProgress({ percent: 0 })
          //   this.is_progress = false

          //   clearInterval(this.set_progress)
          // })
          const formData = new FormData();
          formData.append('business_code', this.upload_file_params.business_code);
          formData.append('need_compress', this.upload_file_params.need_compress);
          formData.append('type', this.upload_file_params.type);
          formData.append('file', file);
          await this.normalUploadFile(file, onSuccess, formData);
        } else {
          await this.vodUpload(file, onSuccess, onProgress).then((res) => {
            this.is_progress = false;
          });
        }
      }
    },
    //设置进度条
    onProgress(file, onProgress) {
      let progress = { percent: 0 };
      let speed = 100 / (file.size / 65000); //上传速度
      let end = Math.round(Math.random() * (99 - 90)) + 90; //生成等待上传成功剩余进度的随机数
      let percent = speed > end ? end : speed;

      this.set_progress = setInterval(() => {
        if (progress.percent + percent <= end) {
          progress.percent += percent;
        } else {
          progress.percent = end;
          clearInterval(this.set_progress);
        }

        onProgress(progress);
        this.progress = progress.percent.toFixed(0);
      }, 100);
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const file_type = file.type.split('/')[0];
        const type = file.type.split('/')[1];
        const accept_list = this.acceptList
          .replaceAll('.', '')
          .split(',')
          .map((v) => v?.toUpperCase()?.trim());

        const suffixArrary = file?.name?.split('.') || [];

        const suffix = suffixArrary?.[suffixArrary.length - 1]?.toUpperCase();

        let upload_state = accept_list?.includes(suffix);

        if (!upload_state) {
          this.$message.error(this.uploadMessage);
          return reject(false);
        }

        if (this.limitSize) {
          const isLimit = file.size / 1024 / 1024 < this.limitSize;
          if (!isLimit) {
            this.$message.error(`上传文件不超过${this.limitSize}MB!`);
            return reject(false);
          }
        }

        if (file_type === 'image') {
          this.custom_request_type = 'image';

          if (this.isNormal && !this.fileParams) {
            this.upload_file_params.need_compress = 1;
            this.upload_file_params.type = 1;
          }

          if (this.getWidth) {
            let that = this;
            let url = window.URL.createObjectURL(file);
            let img = new Image();
            img.src = url;
            img.onload = () => {
              let _w = img.width;
              let _h = img.height;
              if (that.maxW_H && (_w > that.maxW_H.split('*')[0] || _h > that.maxW_H.split('*')[1])) {
                if (that.showMaxErrorMsg) {
                  that.$message.warning(
                    that.maxWidthErrorMsg || `图片像素必须≤768*1024，当前图片为${_w}*${_h}，请检查后重新上传`
                );
                }
                this.$emit('handlePixelError', file);
                return reject(false);
              } else {
                return resolve(true);
              }
            };
          } else {
            if (this.isDuration) {
              file.duration = 1;
              return resolve(true);
            } else {
              return resolve(true);
            }
          }
        } else if (file_type === 'video') {
          this.custom_request_type = 'video';

          this.params_data.fileName = file.name;
          this.params_data.title = file.name.split('.')[0];

          if (this.isNormal && !this.fileParams) {
            this.upload_file_params.need_compress = 2;
            this.upload_file_params.type = 3;
          }

          //如需获取时长，从file的duration取值
          if (this.isDuration) {
            this.getTimes(file).then((res) => {
              file.duration = Math.floor(res) > 1 ? res : 1;
              return resolve(true);
            });
          } else {
            return resolve(true);
          }
        } else if (['PPTX', 'PPT', 'XLSX', 'XLS'].includes(suffix)) {
          this.custom_request_type = 'file';
          this.params_data.fileName = file.name;
          this.params_data.title = file.name.split('.')[0];
          if (this.isNormal && !this.fileParams) {
            this.upload_file_params.need_compress = 2;
            this.upload_file_params.type = 5;
          }
          return resolve(true);
        }
      });
    },
    //获取时长
    getTimes(file) {
      let url = URL.createObjectURL(file);
      let audioElement = new Audio(url);

      return new Promise((resolve, reject) => {
        audioElement.addEventListener('loadedmetadata', function () {
          resolve(parseInt(audioElement.duration));
        });
      });
    },
    handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;
        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.slice(-this.limitFileLength);

          fileList.map((file) => {
            file.thumbUrl = utils.fileSuffixTypeUtil(file.xhr, this.video_suffix_list) ? this.thumb_url : file.xhr;
          });

          if (this.custom_request_type === 'image' || !this.is_vod || this.isNormal) {
            this.progress = 0;
            this.is_progress = false;
            clearInterval(this.set_progress);
          }

          this.file_list = fileList;
          this.$emit('handleUrl', info.file);
        }
      } else {
        return;
      }
    },
    async handlePreview(file) {
      let url = file.xhr || file.url;
      this.$emit('handlePreview', url);
    },
    handleRemove(info) {
      this.$emit('handleUrl', '');
    },
  },
};
</script>

<style lang="less" scoped>
.upload-wrap .upload-wrap-box {
  width: 105px;
  position: relative;

  .upload-box {
    position: relative;
  }

  .progress {
    color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 60px;
    left: 10px;
  }

  .bottom {
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.upload-wrap-box {
  width: 100%;
  position: relative;
  padding-bottom: 20px;
}

.ant-upload-box {
  padding: 3px 12px;
  border: 1px solid #8391a5;
  display: flex;
  align-items: center;
  cursor: pointer;

  .ant-upload-text {
    padding-left: 8px;
  }
}
</style>
